import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {http_auth} from "../../utility/Utils"
import {toast} from "react-toastify"


export const getSliders = createAsyncThunk('slider/getSliders', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.get('/sliders', {
            params: data
        })
        return response.data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})

export const createSlider = createAsyncThunk('slider/createSlider', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.post('/sliders', data)
        return response.data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})

export const deleteSlider = createAsyncThunk('slider/deleteSlider', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.delete(`/sliders/${data}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})

export const patchSlider = createAsyncThunk('slider/patchSlider', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.patch(`/sliders/${data?.id}`, data?.body)
        return response.data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})


export const slidersSlice = createSlice({
    name: 'sliders',
    initialState: {
        sliders: [],
        slider: null,
        currentPage: 0,
        pageCount: 0,
       limit: 10,
        totalCount: 0
    },
    reducers: {
        setSlider: (state, action) => {
            state.slider = action.payload
        }
    },
    extraReducers: {
        [getSliders.fulfilled]: (state, action) => {
            state.sliders = action.payload?.data
            state.currentPage = action.payload?.currentPage
            state.limit = action.payload?.limit
            state.pageCount = action.payload?.pageCount
            state.totalCount = action.payload?.totalCount
            state.isLoading = false
        },
        [getSliders.pending]: (state) => {
            state.isLoading = true
        },
        [getSliders.rejected]: (state, action) => {
            toast.error(action.payload)
            state.isLoading = false
        },

        [createSlider.fulfilled]: (state, action) => {
            state.sliders = [...state.sliders, action.payload?.data]
            state.isLoading = false
        },
        [createSlider.pending]: (state) => {
            state.isLoading = true
        },
        [createSlider.rejected]: (state, action) => {
            toast.error(action.payload)
            state.isLoading = false
        },

        [deleteSlider.fulfilled]: (state, action) => {
            state.sliders = state.sliders.filter(item => item.id !== action?.meta?.arg)
            state.isLoading = false
        },
        [deleteSlider.pending]: (state) => {
            state.isLoading = true
        },
        [deleteSlider.rejected]: (state, action) => {
            toast.error(action.payload)
            state.isLoading = false
        },

        [patchSlider.fulfilled]: (state, action) => {
            state.sliders[state.sliders.findIndex(item => item.id === action.payload?.data?.id)] = action.payload?.data
            state.isLoading = false
        },
        [patchSlider.pending]: (state) => {
            state.isLoading = true
        },
        [patchSlider.rejected]: (state, action) => {
            toast.error(action.payload)
            state.isLoading = false
        }
    }
})

export const {setSlider} = slidersSlice.actions

export default slidersSlice.reducer