import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {http_auth} from "../../utility/Utils"
import {toast} from "react-toastify"


export const getCategories = createAsyncThunk('category/getCategories', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.get('/categories', {
            params: data
        })
        return response.data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})

export const createCategory = createAsyncThunk('category/createCategory', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.post('/categories', data)
        return response.data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})

export const deleteCategory = createAsyncThunk('category/deleteCategory', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.delete(`/categories/${data}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})

export const patchCategory = createAsyncThunk('category/patchCategory', async (data, {rejectWithValue}) => {
    try {
        const response = await http_auth.patch(`/categories/${data?.id}`, data?.body)
        return response.data
    } catch (error) {
        return rejectWithValue(error.message)
    }
})


export const categoriesSlice = createSlice({
    name: 'categories',
    initialState: {
        categories: [],
        category: null,
        currentPage: 0,
        pageCount: 0,
       limit: 10,
        totalCount: 0
    },
    reducers: {
        setCategory: (state, action) => {
            state.category = action.payload
        }
    },
    extraReducers: {
        [getCategories.fulfilled]: (state, action) => {
            state.categories = action.payload?.data
            state.currentPage = action.payload?.currentPage
            state.limit = action.payload?.limit
            state.pageCount = action.payload?.pageCount
            state.totalCount = action.payload?.totalCount
            state.isLoading = false
        },
        [getCategories.pending]: (state) => {
            state.isLoading = true
        },
        [getCategories.rejected]: (state, action) => {
            toast.error(action.payload)
            state.isLoading = false
        },

        [createCategory.fulfilled]: (state, action) => {
            state.categories = [...state.categories, action.payload?.data]
            state.isLoading = false
        },
        [createCategory.pending]: (state) => {
            state.isLoading = true
        },
        [createCategory.rejected]: (state, action) => {
            toast.error(action.payload)
            state.isLoading = false
        },

        [deleteCategory.fulfilled]: (state, action) => {
            state.categories = state.categories.filter(item => item.id !== action?.meta?.arg)
            state.isLoading = false
        },
        [deleteCategory.pending]: (state) => {
            state.isLoading = true
        },
        [deleteCategory.rejected]: (state, action) => {
            toast.error(action.payload)
            state.isLoading = false
        },

        [patchCategory.fulfilled]: (state, action) => {
            state.categories[state.categories.findIndex(item => item.id === action.payload?.data?.id)] = action.payload?.data
            state.isLoading = false
        },
        [patchCategory.pending]: (state) => {
            state.isLoading = true
        },
        [patchCategory.rejected]: (state, action) => {
            toast.error(action.payload)
            state.isLoading = false
        }
    }
})

export const {setCategory} = categoriesSlice.actions

export default categoriesSlice.reducer