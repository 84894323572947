//

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { http, http_auth, TOKEN } from '../../utility/Utils'

// Token va userData-ni localStorage-dan olish
const storedToken = localStorage.getItem(TOKEN) || ''
const storedUserData = JSON.parse(localStorage.getItem('userData')) || {}

export const login = createAsyncThunk('app/login', async (data) => {
  const response = await http.post('/auth', data)
  return response.data
})

export const getUserMe = createAsyncThunk(
  'app/getUserMe',
  async (_, { rejectWithValue }) => {
    try {
      const response = await http_auth.get('/sellers/me')
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    userData: storedUserData,
    isAuth: !!storedToken, // Token bo'lsa, isAuth true bo'ladi
    authModal: false,
    isLoading: false,
    accessToken: storedToken
  },
  reducers: {
    handleLogout: (state) => {
      state.userData = {}
      state.accessToken = ''
      state.isAuth = false
      localStorage.removeItem(TOKEN)
      localStorage.removeItem('userData')
    }
  },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.userData = action.payload?.data?.seller
      state.accessToken = action.payload.data?.token
      state.isAuth = true
      localStorage.setItem(TOKEN, action.payload.data?.token)
      localStorage.setItem(
        'userData',
        JSON.stringify(action.payload?.data?.seller)
      ) // User ma'lumotlarini saqlaymiz
      state.isLoading = false
    },
    [login.pending]: (state) => {
      state.isLoading = true
    },
    [login.rejected]: (state, action) => {
      toast.error(action.error.message)
      state.isAuth = false
      state.isLoading = false
    },

    [getUserMe.fulfilled]: (state, action) => {
      state.userData = action.payload?.data
      state.isAuth = true
      localStorage.setItem('userData', JSON.stringify(action.payload?.data)) // Sahifa yangilanganda foydalanuvchi yo'qolmasligi uchun
      state.isLoading = false
    },
    [getUserMe.pending]: (state) => {
      state.isLoading = true
    },
    [getUserMe.rejected]: (state) => {
      state.isAuth = false
      state.isLoading = false
    }
  }
})

export const { handleLogout } = authSlice.actions

export default authSlice.reducer
